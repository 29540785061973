import React from 'react';
import * as styles from './index.styles';

import { applicationConfig } from '~/constants/applicationConfig';
import Image from 'next/image';

export type Props = React.PropsWithChildren<{}>;

export const StartPageLayout: React.FC<Props> = ({ children }) => (
  <div css={styles.outerContainer}>
    <div css={styles.containerTG}>
      <div css={styles.logoTitle.container}>
        <Image width={256} height={56} src={'/assets/logo/with-icon.svg'} alt="タイミー" />
        <div css={styles.logoTitle.title}>{applicationConfig.serviceName}</div>
      </div>

      {children}
    </div>
  </div>
);
