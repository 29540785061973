var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1093a88f9be14acc38bc8c0cd0e3c1e1b8824ddc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { shouldIgnoreError } from '~/libs/sentry/shouldIgnoreError';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://38a8b45af52d44f29e867f4cc37a1e4a@o247126.ingest.sentry.io/4503884414517248',
  environment,
  enabled: environment !== 'development' && environment !== 'test',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend: (error, hint) => {
    return shouldIgnoreError(error, hint) ? null : error;
  },
  ignoreErrors: [
    // NOTE: https://blog.sentry.io/making-your-javascript-projects-less-noisy/
    /**
     * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
     * This can also be filtered by enabling the browser extension inbound filter
     */
    "TypeError: can't access dead object",
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
  ],
});
